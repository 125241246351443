import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    caption: 'Scout',
    type: 'Software Developer at Homer Bot',
    description:
      'Scout is a real-estate analytic platform React project. I was responsible for back-end and managing database using Active Record and I wrote scrapers using Scrapy in Python.',
    time: 'Dec 2019 - Mar 2020',
    techStack: [
      { id: '1', tech: 'Ruby on Rails' },
      { id: '2', tech: 'Python' },
      { id: '3', tech: 'PostgreSQL' },
    ],
  },
  {
    id: '2',
    source: full02,
    thumbnail: thumb02,
    caption: 'Homer',
    type: 'Software Developer at Homer Bot',
    description:
      "Homer Slack Chatbot helps organizations find homes for their employees. I participated in the design, prototype and development of the chatbot. One of the team leads for BCIT's ISSP from Feb to May 2019. Gained experiences in natural language process, cloud computing and various APIs during this project.",
    time: 'Nov 2018 - Dec 2019',
    techStack: [
      { id: '4', tech: 'Node.js' },
      { id: '5', tech: 'Dialogflow' },
      { id: '6', tech: 'Heroku' },
      { id: '7', tech: 'Slack API' },
      { id: '8', tech: 'Google API' },
      { id: '8', tech: 'Airtable API' },
    ],
  },
  {
    id: '6',
    source: full06,
    thumbnail: thumb06,
    caption: 'Snap Food',
    type: 'Group Project at Lighthouse Labs',
    description:
      'This is an application specically for Android that makes reporting discounted grocery items faster and searching for real time food deals easier! My responsibility is making the front-end components according to the wireframe and connecting the front-end routes using React Navigation.',
    time: 'May 2020',
    githubLink: 'https://github.com/linyh0207/snapFood-frontend',
    techStack: [{ id: '16', tech: 'React Native' }],
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    caption: 'Runny Bunny',
    type: 'XdHacks Hackathon by UBC',
    description:
      'Runny bunny is an interactive mobile app integrated with FitBit which aims to make exercising fun for children to decrease the risk of childhood obesity. The UI was inspired by Tamagotchi, a retro game that brings back childhood memories to most people. My responsibility is to set up the dev environment using Expo and focused mainly on the front-end.',
    time: 'Feb 9 - 10, 2019',
    githubLink: 'https://github.com/linyh0207/runny-bunny',
    techStack: [{ id: '9', tech: 'React Native' }],
  },
  {
    id: '4',
    source: full04,
    thumbnail: thumb04,
    caption: 'Mindfull',
    type: 'Group Final Project at Lighthouse Labs',
    description:
      'This is a mobile application that makes logging food inventory faster and searching for recipe ideas more fun! My main task is setting up the Clarifai API object recognition function and barcode function, which allows user to log their food by taking a photo or scan the barcode from their phone. Then, the keywords will run the Yummly API for recipe recommendation.',
    time: 'Oct 2018',
    githubLink:
      'https://github.com/linyh0207/Mindfull-Front/tree/master/Mindfull-Front',
    techStack: [
      { id: '10', tech: 'React Native' },
      { id: '11', tech: 'Clarifai API' },
      { id: '12', tech: 'Yummly API' },
    ],
  },
  {
    id: '5',
    source: full05,
    thumbnail: thumb05,
    caption: "Angie & Frank's Pizza",
    type: 'Group Midterm Project at Lighthouse Labs',
    time: 'Sept 2018',
    description:
      'This is a full stack web application that allows customers to place pizza orders online and receive an SMS using the Twillio API when the order is ready. The owner will get a notification via SMS when the order is placed. My responsibility was creating the server and database back-end work.',
    githubLink: 'https://github.com/linyh0207/Angie-Frank-s-Order-Pizza-Online',
    techStack: [
      { id: '13', tech: 'JavaScript' },
      { id: '14', tech: 'Knex' },
      { id: '15', tech: 'PostgreSQL' },
    ],
  },
]

class HomeIndex extends React.Component {
  render() {
    const siteTitle = 'Angela Lin'
    const siteDescription = 'Software developer based in Vancouver, BC Canada.'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>About Me</h2>
            </header>
            <p>
              A software developer with diverse backgrounds and experiences.
              <br />
              Obtained a marketing degree from SFU. Graduated from Lighthouse
              Labs' web development bootcamp in 2018. Have work experience in
              tech, finance, real estate and retail field. Critical thinker with
              strong problem-solving and analytical skills. Self-motivated and
              highly organized to be able to work remotely. Enjoy working as a
              team member as well as independently.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              "Get comfortable with being uncomfortable"
            </p>
          </section>

          <section id="two">
            <h2>Recent Work</h2>

            <Gallery
              images={DEFAULT_IMAGES.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  type,
                  description,
                  time,
                  githubLink,
                  techStack,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  type,
                  description,
                  time,
                  githubLink,
                  techStack,
                })
              )}
            />

            <ul className="actions">
              <li>
                <a
                  href="https://github.com/linyh0207"
                  className="button icon fa-github"
                >
                  Full Portfolio
                </a>
              </li>
            </ul>
          </section>

          <section id="three">
            <h2>Get In Touch</h2>
            <div className="row">
              <div className="4u 12u$(small)">
                <ul className="labeled-icons">
                  <li>
                    <h3 className="icon fa-home">
                      <span className="label">Address</span>
                    </h3>
                    Vancouver, BC
                    <br />
                    Canada
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
