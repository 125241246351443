import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'

class Gallery extends Component {
  constructor() {
    super()

    this.state = {
      lightboxIsOpen: false,
      selectedIndex: 0,
    }

    this.toggleLightbox = this.toggleLightbox.bind(this)
  }
  toggleLightbox(selectedIndex) {
    this.setState(state => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }))
  }
  renderGallery(images) {
    if (!images) return

    const gallery = images.map((obj, i) => {
      let techStack = obj.techStack.map(t=> {
        return (
          <span
            style={{
              border: '2px solid #E8E8E8',
              borderRadius: '10px',
              background: '#E8E8E8',
              marginRight: '5px',
              fontSize: '0.6em',
            }}
            key={t.id}
          >
            {t.tech}
          </span>
        )
      })
      return (
        <article className="6u 12u$(xsmall) work-item" key={i}>
          <a
            className="image fit thumb"
            href={obj.source}
            onClick={e => {
              e.preventDefault()
              this.toggleLightbox(i)
            }}
          >
            <img src={obj.thumbnail} />
          </a>
          <a href={obj.githubLink}>
            <h3>{obj.caption}</h3>
          </a>
          <p>
            <strong>{obj.type}</strong>
          </p>
          <p>{obj.time}</p>
        <div>{techStack}</div>
          {/* <div>
            <span
              style={{
                border: '2px solid #E8E8E8',
                borderRadius: '10px',
                background: '#E8E8E8',
                marginRight: '5px',
                fontSize: '0.6em',
              }}
            >
              Ruby on Rails
            </span>
            <span
              style={{
                border: '1.5px solid #DCDCDC',
                borderRadius: '10px',
                background: '#DCDCDC',
              }}
            >
              Ruby on Rails
            </span>
            <span className="inline-block bg-grey-lighter rounded-full px-3 py-1 text-sm font-semibold text-grey-darker">
              #mountains
            </span>
          </div> */}
          <p>{obj.description}</p>
        </article>
      )
    })

    return <div className="row">{gallery}</div>
  }
  render() {
    const { images } = this.props
    const { selectedIndex, lightboxIsOpen } = this.state

    return (
      <div>
        {this.renderGallery(images)}
        <ModalGateway>
          {lightboxIsOpen && (
            <Modal onClose={this.toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          )}
        </ModalGateway>
      </div>
    )
  }
}

Gallery.displayName = 'Gallery'
Gallery.propTypes = {
  images: PropTypes.array,
}

export default Gallery
